/** @format */

import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {store} from "./app/store";
import App from "./App";
import "./index.css";
import {BrowserRouter, Routes, Route, Navigate, Outlet} from "react-router-dom";
import IdentityPage from "./features/identity/Identity";
import Table from "./features/table/Table";
import Welkom from "./features/welkom/Welkom";
import Summary from "./features/summary/Summary";
import Success from "./features/success/Success";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App />}>
						<Route index element={<Welkom />} />
						<Route path="gegevens" element={<IdentityPage />} />
						<Route path="kiesjevak" element={<Table />} />
						<Route path="summary" element={<Summary />} />
						<Route path="success" element={<Success />} />
						<Route path="*" element={<Navigate to={"/"} />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);
