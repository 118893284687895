/** @format */

import React from "react";
import PropTypes from "prop-types";

interface ITopbarProps {}
const Topbar = ({}: ITopbarProps) => {
	return (
		<div className="flex items-center justify-center p-2">
			<a href="https://hagenfaber.eu" target="_blank">
				<img
					src="https://hagenfaber.eu/assets/hagenfaber.png"
					className="h-12 w-12 rounded-full"
				/>
			</a>
			<span className="ml-2">
				Gemaakt door&nbsp;
				<a
					href="https://hagenfaber.eu"
					className="underline"
					target="_blank"
				>
					HagenFaber
				</a>
			</span>
		</div>
	);
};

export default Topbar;
