/** @format */

import React from "react";
import PropTypes from "prop-types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import {updateIdentity, setJeugdBeweging} from "./identity.slice";
import {setCompleted, setError} from "../../App.slice";
import {saveIdentidyAsync} from "./identity.actions";
import {useNavigate} from "react-router-dom";
import {setActivePage, setInfoSuccess} from "../../App.slice";

import {Switch} from "@headlessui/react";

function classNames(...classes: any) {
	return classes.filter(Boolean).join(" ");
}

interface IIdentityPageProps {}
const IdentityPage = ({}: IIdentityPageProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const table = useAppSelector((state) => state.table);
	const app = useAppSelector((state) => state.app);
	const identity = useAppSelector((state) => state.identity);

	const setEnabled = () => {
		dispatch(setJeugdBeweging(!identity.jeugdBeweging));
	};

	const values_onchange = (e: any) => {
		if (e.target.name == "naam")
			dispatch(
				updateIdentity({
					name: e.target.value,
					email: identity.variableIdentity.email,
					bemail: identity.variableIdentity.bemail,
					scope: identity.variableIdentity.scope,
					telefoon: identity.variableIdentity.telefoon,
					naamJeugdBeweging:
						identity.variableIdentity.naamJeugdBeweging,

					isJeugdbeweging: identity.variableIdentity.isJeugdbeweging,
				})
			);
		else if (e.target.name == "email")
			dispatch(
				updateIdentity({
					name: identity.variableIdentity.name,
					email: e.target.value,
					bemail: identity.variableIdentity.bemail,
					scope: identity.variableIdentity.scope,
					telefoon: identity.variableIdentity.telefoon,
					naamJeugdBeweging:
						identity.variableIdentity.naamJeugdBeweging,
					isJeugdbeweging: identity.variableIdentity.isJeugdbeweging,
				})
			);
		else if (e.target.name == "bemail")
			dispatch(
				updateIdentity({
					name: identity.variableIdentity.name,
					email: identity.variableIdentity.email,
					bemail: e.target.value,
					scope: identity.variableIdentity.scope,
					telefoon: identity.variableIdentity.telefoon,
					naamJeugdBeweging:
						identity.variableIdentity.naamJeugdBeweging,
					isJeugdbeweging: identity.variableIdentity.isJeugdbeweging,
				})
			);
		else if (e.target.name == "telefoon")
			dispatch(
				updateIdentity({
					name: identity.variableIdentity.name,
					email: identity.variableIdentity.email,
					bemail: identity.variableIdentity.bemail,
					scope: identity.variableIdentity.scope,
					telefoon: e.target.value,
					naamJeugdBeweging:
						identity.variableIdentity.naamJeugdBeweging,
					isJeugdbeweging: identity.variableIdentity.isJeugdbeweging,
				})
			);
		else if (e.target.name == "jgbwg")
			dispatch(
				updateIdentity({
					name: identity.variableIdentity.name,
					email: identity.variableIdentity.email,
					bemail: identity.variableIdentity.bemail,
					scope: identity.variableIdentity.scope,
					telefoon: identity.variableIdentity.telefoon,
					naamJeugdBeweging: e.target.value,
					isJeugdbeweging: identity.variableIdentity.isJeugdbeweging,
				})
			);
	};

	const navigateTo = (path: string) => {
		dispatch(setActivePage(path));
		navigate(path);
	};
	const button_click = (e: any) => {
		e.preventDefault();
		// navigate("/kiesjevak");
		console.log("submit");
		if (e.target["naam"].value === "") {
			dispatch(setError("Vul je naam in"));
			return;
		}
		if (e.target["email"].value === e.target["bemail"].value) {
			dispatch(setInfoSuccess(true));
			dispatch(saveIdentidyAsync());
			dispatch(setCompleted("/gegevens"));
			navigateTo("/kiesjevak");
		} else {
			dispatch(setError("Emails komen niet overeen"));
		}
	};
	return (
		<div className="w-full flex justify-center">
			<form onSubmit={button_click} className="w-full sm:w-2/3 lg:w-1/2">
				{app.userInfoError !== "" ? (
					<div className="bg-red-300 p-5 mt-10 border-l-4 border-red-500 w-full">
						{app.userInfoError}
					</div>
				) : null}

				<div className="mt-4 w-full">
					<label
						htmlFor="naam"
						className="block text-sm font-medium text-gray-700"
					>
						Naam
					</label>
					<div className="mt-1">
						<input
							onChange={values_onchange}
							value={identity.variableIdentity.name}
							type="text"
							name="naam"
							id="naam"
							className="p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							placeholder="Jouw naam"
						/>
					</div>
				</div>
				<div className="mt-4">
					<label
						htmlFor="email"
						className="block text-sm font-medium text-gray-700"
					>
						Email
					</label>
					<div className="mt-1">
						<input
							onChange={values_onchange}
							value={identity.variableIdentity.email}
							type="email"
							name="email"
							id="email"
							className="p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							placeholder="jouw@email.be"
						/>
					</div>
				</div>
				<div className="mt-4">
					<label
						htmlFor="bemail"
						className="block text-sm font-medium text-gray-700"
					>
						Bevestig email
					</label>
					<div className="mt-1">
						<input
							onChange={values_onchange}
							value={identity.variableIdentity.bemail}
							type="email"
							name="bemail"
							id="bemail"
							className="p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							placeholder="jouw@email.be"
						/>
					</div>
				</div>
				<div className="mt-4">
					<label
						htmlFor="telefoon"
						className="block text-sm font-medium text-gray-700"
					>
						Telefoon
					</label>
					<div className="mt-1">
						<input
							onChange={values_onchange}
							value={identity.variableIdentity.telefoon}
							type="telefoon"
							name="telefoon"
							id="telefoon"
							className="p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							placeholder="0412345678"
						/>
					</div>
				</div>
				<Switch.Group
					as="div"
					className="flex items-center justify-between mt-4"
				>
					<span className="flex flex-grow flex-col">
						<Switch.Label
							as="span"
							className="text-sm font-medium leading-6 text-gray-900"
							passive
						>
							Jeugdbeweging
						</Switch.Label>
						<Switch.Description
							as="span"
							className="text-sm text-gray-500"
						>
							Ik zit in een jeugdbeweging
						</Switch.Description>
					</span>
					<Switch
						checked={identity.jeugdBeweging}
						onChange={setEnabled}
						className={classNames(
							identity.jeugdBeweging
								? "bg-indigo-600"
								: "bg-gray-200",
							"relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
						)}
					>
						<span
							aria-hidden="true"
							className={classNames(
								identity.jeugdBeweging
									? "translate-x-5"
									: "translate-x-0",
								"pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
							)}
						/>
					</Switch>
				</Switch.Group>
				{identity.jeugdBeweging ? (
					<div className="mt-4">
						<label
							htmlFor="jgbwg"
							className="block text-sm font-medium text-gray-700"
						>
							Naam jeugdbeweging
						</label>
						<div className="mt-1">
							<input
								onChange={values_onchange}
								value={
									identity.variableIdentity.naamJeugdBeweging
								}
								type="jgbwg"
								name="jgbwg"
								id="jgbwg"
								className="p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
								placeholder="Naam jeugdbeweging"
							/>
						</div>
					</div>
				) : null}

				<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
					<input
						type="submit"
						value="Doorgaan"
						className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
					/>
				</div>
			</form>
		</div>
	);
};

export default IdentityPage;
