import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'
import { io, Socket } from 'socket.io-client';
import { IWebSocketState, IWebSocketMessage, IOwner, IWebSocketConfig } from './websocket.interface'
import { addListenersAsync, addOwnersAsync, addSelectedAsync } from './websocket.actions';

const WS_BASE = "https://api.schijt-je-rijk.hagenfaber.eu";
// const WS_BASE = "http://localhost:8093";

const initialState: IWebSocketState = {
    socket: io(WS_BASE),
    config: [],
    loading: true,
    connected: false
}

const WebSocketSlice = createSlice({

    name: 'WebSocket',
    initialState,
    reducers: {
        sendMessage(state, action: PayloadAction<IWebSocketMessage>) {
            const message: IWebSocketMessage = action.payload;
            state.socket.emit("message", message.data);
        },
        setConfig(state, action: PayloadAction<IWebSocketConfig[]>) {
            state.config = action.payload;

            state.loading = false;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setConnected(state, action: PayloadAction<boolean>) {
            state.connected = action.payload;
        }
    },
    // extra reducers
    extraReducers: (builder) => {
        // builder
        //     .addCase(addListenersAsync.pending, (state) => {})
        //     .addCase(addListenersAsync.rejected, (state) => {})
        //     .addCase(addListenersAsync.fulfilled, (state) => {})

        //     .addCase(addOwnersAsync.pending, (state) => {})
        //     .addCase(addOwnersAsync.rejected, (state) => {})
        //     .addCase(addOwnersAsync.fulfilled, (state) => {})

        //     .addCase(addSelectedAsync.pending, (state) => {})
        //     .addCase(addSelectedAsync.rejected, (state) => {})
        //     .addCase(addSelectedAsync.fulfilled, (state) => {})

    }

})

export const { sendMessage, setConfig, setLoading, setConnected } = WebSocketSlice.actions
export default WebSocketSlice.reducer
