import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateIdentity, saveIdentity, setMySelected, forceSetMySelected } from './identity.slice';
import { updateMySelected } from './identity.slice';
import { addSelectedAsync } from '../webSocket/websocket.actions';
import { IOwner } from '../webSocket/websocket.interface';
import { IIdentity } from './identity.interface';

const host = 'localhost:3000';

export const saveIdentidyAsync = createAsyncThunk(
    'identity/saveIdentidy',
     async (_, {dispatch, getState}) => {
         const {identity} = getState() as any;
         const mySelected = identity.mySelected;
         dispatch(updateMySelected({}));
         dispatch(addSelectedAsync());
         dispatch(saveIdentity());
         dispatch(updateMySelected(mySelected));
         dispatch(addSelectedAsync());
     }
 )

export const addMySelectedAsync = createAsyncThunk(
   'identity/addMySelected',
   async (_ :{x: string, y: string, scope:string}, {dispatch, getState}) => {
    const {identity, table} = getState() as any;

    const selectedTable: {
		[scope: string]: {
            [x: string]: {
                [y: string]: IIdentity;
            }
        };
    } = {};

    console.log(identity.mySelected[_.x], _.x);
    selectedTable[_.scope] = {
        ...identity.mySelected[_.scope],
        [_.x]: {
            ...identity.mySelected[_.x],
            [_.y]: {
                name: identity.identity.name,
                email: identity.identity.email,
                bemail: identity.identity.email,
                telefoon: identity.identity.telefoon,
                naamJeugdBeweging: identity.identity.naamJeugdBeweging,
                isJeugdbeweging: identity.identity.isJeugdbeweging,
                scope: table.selectedScope.naam
            }
        }
    };




        dispatch(forceSetMySelected(selectedTable));

    }
)


