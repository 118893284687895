/** @format */

import React from "react";
import PropTypes from "prop-types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useNavigate} from "react-router-dom";
import {setActivePage, setCompleted} from "../../App.slice";
import Divider from "../../components/Divider";

interface IWelkomProps {}
const Welkom = ({}: IWelkomProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const table = useAppSelector((state) => state.table);
	const websocket = useAppSelector((state) => state.websocket);

	const navigateTo = (path: string) => {
		dispatch(setActivePage(path));
		dispatch(setCompleted("/"));
		navigate(path);
	};
	return (
		<div className="w-full flex justify-center">
			<div className="w-full sm:w-2/3 lg:w-1/2">
				<div className="text-3xl font-bold">
					Schijt Je Rijk 2024 - Chiro Heilig Hart Zottegem
				</div>
				<p className="mt-4">
					Nu het lente wordt maken onze dieren zich weer op voor ons
					jaarlijks evenement "Schijt Je Rijk".
				</p>
				<p className="font-bold mt-10">
					Wat houdt "Schijt Je Rijk" precies in?
				</p>
				<p className="mt-4">
					Onze dieren zullen één voor één op het Chiro terrein worden
					losgelaten. Het veld wordt onderverdeeld in vakjes en in
					welk vakje het dier als eerste zijn behoefte doet wint. Door
					lotjes te kopen kan je een vakje reserveren, er zal je dan
					via mail een coördinaat worden toegestuurd.
				</p>
				<p className="mt-4">
					Iedereen is welkom op 20 april vanaf 13u op onze Chiro
					(Kasteelstraat 30 Zottegem). Terwijl de dieren hun stinkende
					best doen kan u genieten van een hapje en een drankje of
					zelfs een eendje vissen aan één van onze standjes. Lotjes en
					het bijhorende vakje kan u hier online bestellen.
				</p>
				<div className="mt-4 bg-yellow-300 text-yellow-700 p-3 mt-3 border-l-4 border-yellow-700">
					OPGELET! Er zijn 2 categorieën aangemaakt:
					<ul className="list-disc ml-6">
						<li>één voor jeugdbewegingen</li>
						<li>één voor de "normale mens"</li>
					</ul>
					Zit je in een jeugdbeweging dan mag je uiteraard aan beide
					categorieën deelnemen.
				</div>
				<p className="font-bold mt-10 text-2xl">Prijzen</p>
				<p className="mt-4 font-medium text-lg">
					Voor de jeugdbeweging:
				</p>
				<table className="w-full border-black rounded-sm overflow-hidden">
					<thead>
						<tr className="">
							<th className="border text-left">Dier</th>
							<th className="border text-left">Prijs</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="border">Geit</td>
							<td className="border">Bakken bier</td>
						</tr>
					</tbody>
				</table>
				<p className="font-medium mt-4">
					Per 15 verkochte lotjes voegen we één bak bier toe aan de
					prijzenpot!!!
				</p>
				<p className="bg-yellow-300 text-yellow-700 p-3 mt-3 border-l-4 border-yellow-700">
					OPGELET: Dit is niet per persoon! Het gaat om 15 lotjes dat
					door leden/leiding over alle jeugdbewegingen samen worden
					gekocht.
				</p>
				<Divider className="mt-4" />
				<p className="mt-5">
					<span className="mt-4 font-medium text-lg">
						Voor de "normale mens":
					</span>
				</p>
				<table className="w-full border-black rounded-sm overflow-hidden">
					<thead>
						<tr className="">
							<th className="border text-left">Dier</th>
							<th className="border text-left">Prijs</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="border">Varken</td>
							<td className="border">€ 50</td>
						</tr>
						<tr>
							<td className="border">Schaap</td>
							<td className="border">€ 100</td>
						</tr>
						<tr>
							<td className="border">Kalfje</td>
							<td className="border">€ 150</td>
						</tr>
					</tbody>
				</table>
				<p className="mt-4">
					Nadat je jouw bestelling aangeklikt hebt, komt deze in het
					winkelmandje terecht. De stappen die hierna volgen zijn
					vanzelfsprekend. Afsluiten van de bestelling doet u door de
					betaling te volbrengen.
				</p>
				<p className="mt-4 font-medium">
					Alvast bedankt voor jullie steun!
				</p>
				<p className="mt-1 font-medium">
					Veel succes en onthoud: gewonnen geld is dubbel zo leuk als
					verdiend geld!
				</p>

				<div className="mt-10 w-full flex justify-end">
					<button
						type="button"
						className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
						onClick={() => navigateTo("/gegevens")}
					>
						Doorgaan
					</button>
				</div>
			</div>
		</div>
	);
};

export default Welkom;
