/** @format */

import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RadioGroup} from "@headlessui/react";

import {useDispatch} from "react-redux";

import {
	addListenersAsync,
	addOwnersAsync,
	addSelectedAsync,
} from "../webSocket/websocket.actions";
import {sendMessage} from "../webSocket/websocket.slice";
import {
	addMySelected,
	removeSelected,
	setMySelected,
	updateIdentity,
} from "../identity/identity.slice";

import {addMySelectedAsync} from "../identity/identity.actions";
import {useNavigate} from "react-router-dom";
import {setActivePage, setCompleted} from "../../App.slice";
import {saveIdentidyAsync} from "../identity/identity.actions";
import {IWebSocketConfig} from "../webSocket/websocket.interface";
import {setSelectedScope} from "./table.slice";
import {setTotalPrice} from "../identity/identity.slice";

interface Props {}
const Table = ({}: Props) => {
	const dispatch = useAppDispatch();
	const table = useAppSelector((state) => state.table);
	const websocket = useAppSelector((state) => state.websocket);

	const identity = useAppSelector((state) => state.identity);
	const app = useAppSelector((state) => state.app);

	const navigate = useNavigate();

	const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

	const navigateTo = (path: string) => {
		dispatch(setActivePage(path));
		navigate(path);
	};

	const changeSelected = (scope: any) => {
		// dispatch(setMySelected([]));
		dispatch(
			updateIdentity({
				name: identity.identity.name,
				email: identity.identity.email,
				bemail: identity.identity.bemail,
				telefoon: identity.identity.telefoon,
				scope: scope.naam,
				naamJeugdBeweging: identity.identity.naamJeugdBeweging,
				isJeugdbeweging: identity.identity.isJeugdbeweging,
			})
		);
		dispatch(saveIdentidyAsync());
		dispatch(setSelectedScope(scope));
	};

	useEffect(() => {
		if (
			!app.steps.filter((step) => step.href === "/gegevens")[0].completed
		) {
			navigateTo("/");
		}
		if (table.selectedScope !== undefined) {
			// dispatch(setMySelected([]));
			dispatch(setSelectedScope(table.selectedScope));
		} else if (websocket.config[0]) {
			dispatch(setMySelected([]));
			dispatch(setSelectedScope(websocket.config[0]));
		}

		// console.log(websocket.config);
	}, []);

	useEffect(() => {
		// check the price of the selected scope
		const selected: any[] = [];

		for (const scope in identity.mySelected) {
			const scopes: any[] = [];
			for (const x in identity.mySelected[scope]) {
				for (const y in identity.mySelected[scope][x]) {
					const a = parseInt(y);
					const xCoord = ALPHABET[a];
					scopes.push(`${xCoord}${x}`);
				}
			}
			const scopeObj = websocket.config.filter(
				(config) => config.naam === scope
			)[0];

			selected.push({
				scope: scopeObj,
				coords: scopes,
			});
		}
		let totalPrice: number = 0;

		for (const scope of selected) {
			let price = scope.scope.prijs1VakLot;

			let price3 = scope.scope.prijs3VakLot;

			let pairsOf3 = Math.floor(scope.coords.length / 3);
			let rest = scope.coords.length % 3;

			// if (scope.coords.length >= 3) {
			// 	price = price3;
			// }
			let totPrice = price3 * pairsOf3;
			totPrice += price * rest;
			totalPrice += Math.round(totPrice * 100) / 100;
		}
		dispatch(setTotalPrice(totalPrice));
	}, [identity.mySelected]);

	const addOwner = () => {
		dispatch(setCompleted("/kiesjevak"));
		navigateTo("/summary");
		// dispatch(addOwnersAsync());
	};

	function classNames(...classes: any) {
		return classes.filter(Boolean).join(" ");
	}

	const isEmpty = (obj: any) => {
		return Object.keys(obj).length === 0;
	};

	if (table.selectedScope === undefined) {
		return <div>Er zijn geen dieren beschikbaar</div>;
	}
	if (!table.loading && !websocket.loading) {
		return (
			<div className="flex flex-col">
				<RadioGroup
					value={table.selectedScope}
					onChange={(scope) => changeSelected(scope)}
				>
					<RadioGroup.Label className="sr-only">
						{" "}
						Privacy setting{" "}
					</RadioGroup.Label>
					<div className="-space-y-px rounded-md bg-white">
						{websocket.config.map((scope, scopeIdx) => {
							if (scope.voorJeugdbeweging) {
								if (identity.identity.isJeugdbeweging) {
									return (
										<RadioGroup.Option
											key={scope.id}
											value={scope}
											className={({checked}: any) =>
												classNames(
													scopeIdx === 0
														? "rounded-tl-md rounded-tr-md"
														: "",
													scopeIdx ===
														websocket.config
															.length -
															1
														? "rounded-bl-md rounded-br-md"
														: "",
													checked
														? "z-10 border-indigo-200 bg-indigo-50"
														: "border-gray-200",
													"relative flex cursor-pointer border p-4 focus:outline-none"
												)
											}
										>
											{({active, checked}) => (
												<>
													<span
														className={classNames(
															checked
																? "bg-indigo-600 border-transparent"
																: "bg-white border-gray-300",
															active
																? "ring-2 ring-offset-2 ring-indigo-600"
																: "",
															"mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
														)}
														aria-hidden="true"
													>
														<span className="rounded-full bg-white w-1.5 h-1.5" />
													</span>
													<span className="ml-3 flex flex-col">
														<RadioGroup.Label
															as="span"
															className={classNames(
																checked
																	? "text-indigo-900"
																	: "text-gray-900",
																"block text-sm font-medium"
															)}
														>
															{scope.naam}
														</RadioGroup.Label>
														<RadioGroup.Description
															as="span"
															className={classNames(
																checked
																	? "text-indigo-700"
																	: "text-gray-500",
																"block text-sm"
															)}
														>
															{scope.beschrijving}
														</RadioGroup.Description>
													</span>
												</>
											)}
										</RadioGroup.Option>
									);
								}
							} else {
								return (
									<RadioGroup.Option
										key={scope.id}
										value={scope}
										className={({checked}: any) =>
											classNames(
												scopeIdx === 0
													? "rounded-tl-md rounded-tr-md"
													: "",
												scopeIdx ===
													websocket.config.length - 1
													? "rounded-bl-md rounded-br-md"
													: "",
												checked
													? "z-10 border-indigo-200 bg-indigo-50"
													: "border-gray-200",
												"relative flex cursor-pointer border p-4 focus:outline-none"
											)
										}
									>
										{({active, checked}) => (
											<>
												<span
													className={classNames(
														checked
															? "bg-indigo-600 border-transparent"
															: "bg-white border-gray-300",
														active
															? "ring-2 ring-offset-2 ring-indigo-600"
															: "",
														"mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
													)}
													aria-hidden="true"
												>
													<span className="rounded-full bg-white w-1.5 h-1.5" />
												</span>
												<span className="ml-3 flex flex-col">
													<RadioGroup.Label
														as="span"
														className={classNames(
															checked
																? "text-indigo-900"
																: "text-gray-900",
															"block text-sm font-medium"
														)}
													>
														{scope.naam}
													</RadioGroup.Label>
													<RadioGroup.Description
														as="span"
														className={classNames(
															checked
																? "text-indigo-700"
																: "text-gray-500",
															"block text-sm"
														)}
													>
														{scope.beschrijving}
													</RadioGroup.Description>
												</span>
											</>
										)}
									</RadioGroup.Option>
								);
							}
						})}
					</div>
				</RadioGroup>
				<div className="p-2 mt-10">
					<div className="flex items-center">
						<div className="min-h-[20px] min-w-[20px] bg-yellow-300 rounded-full"></div>
						<div className="ml-2">
							Dit zijn velden die al gekozen zijn.
						</div>
					</div>
					<div className="flex items-center mt-2">
						<div className="min-h-[20px] min-w-[20px] bg-green-400 rounded-full"></div>
						<div className="ml-2">
							Dit zijn de velden die je zelf hebt geselecteerd.
						</div>
					</div>
					<div className="flex items-center mt-2">
						<div className="min-h-[20px] min-w-[20px] bg-red-400 rounded-full"></div>
						<div className="ml-2">
							Dit zijn de velden die al gekozen en betaald zijn.
						</div>
					</div>
					<div className="flex items-center mt-2">
						<div className="min-h-[20px] min-w-[20px] bg-gray-200 rounded-full"></div>
						<div className="ml-2">
							Dit zijn de velden die momenteel door iemand anders
							geselcteerd zijn.
						</div>
					</div>
				</div>
				<div>
					Totaal:
					<span className="font-bold">€{identity.totalPrice}</span>
				</div>
				<table className="mt-10 w-full h-full rounded-lg overflow-hidden table-fixed">
					<thead>
						<tr>
							<th></th>
							{Array.from({length: table.ncols}, (_, i) => (
								<th key={i} className="text-center">
									{ALPHABET[i]}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{Array.from({length: table.nrows}, (_, i) => (
							<tr key={i} className="w-full">
								<th>{i}</th>
								{Array.from({length: table.ncols}, (_, j) => {
									// console.log(selectedScope.naam);
									let owner: any = null;
									let mySelected: any = null;
									let selected = null;

									if (table.selectedScope) {
										owner =
											table.owners[
												table.selectedScope.naam
											]?.[i]?.[j];
										mySelected =
											identity.mySelected[
												table.selectedScope.naam
											]?.[i]?.[j];
										selected =
											identity.selected[
												table.selectedScope.naam
											]?.[i]?.[j];
									}

									let color: string =
										"bg-white hover:bg-gray-100";

									if (selected) {
										if (
											selected.name ===
												identity.identity.name &&
											selected.email ===
												identity.identity.email &&
											selected.scope ===
												table.selectedScope?.naam
										) {
											if (mySelected)
												color =
													"bg-green-400 hover:bg-green-300";
											else
												color =
													"bg-white hover:bg-gray-100";
										} else {
											color =
												"bg-gray-200 hover:bg-gray-100";
										}
									}
									// if (!mySelected) {
									// 	color = "bg-gray-200 hover:bg-gray-100";
									// }
									if (owner) {
										if (owner.betaald) {
											color =
												"bg-red-400 hover:bg-red-100";
										} else {
											color =
												"bg-yellow-300 hover:bg-yellow-100";
										}
									}
									return (
										<td
											key={j}
											onClick={() => {
												if (!owner) {
													if (
														mySelected &&
														table.selectedScope
													) {
														dispatch(
															removeSelected({
																x: i.toString(),
																y: j.toString(),
																scope: table
																	.selectedScope
																	.naam,
															})
														);
													} else {
														if (table.selectedScope)
															dispatch(
																addMySelected({
																	x: i.toString(),
																	y: j.toString(),
																	scope: table
																		.selectedScope
																		.naam,
																})
															);
													}
													dispatch(
														addSelectedAsync()
													);
												}
											}}
											className={` border border-gray-200 ${color} transition duration-300 ease-in-out`}
										></td>
									);
								})}
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-10 w-full flex justify-end">
					{JSON.stringify(identity.mySelected) !== "{}" ? (
						<button
							type="button"
							className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
							onClick={addOwner}
						>
							Doorgaan
						</button>
					) : null}
				</div>
			</div>
		);
	}
	return <>Loading</>;
};

export default Table;
