/** @format */

import React from "react";

import {useAppDispatch, useAppSelector} from "./app/hooks";
import {
	addListenersAsync,
	addOwnersAsync,
} from "./features/webSocket/websocket.actions";
import {updateIdentity} from "./features/identity/identity.slice";
import {saveIdentidyAsync} from "./features/identity/identity.actions";
import {Outlet, Link, useNavigate} from "react-router-dom";

import Notification from "./features/notifications/Notifications";
import {setActivePage} from "./App.slice";
import Topbar from "./components/Topbar";

function App() {
	const dispatch = useAppDispatch();
	const table = useAppSelector((state) => state.table);
	const app = useAppSelector((state) => state.app);
	const websocket = useAppSelector((state) => state.websocket);
	const navigate = useNavigate();

	const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

	React.useEffect(() => {
		console.log("App.tsx useEffect");
		dispatch(addListenersAsync());
	}, []);

	const navigateTo = (path: string) => {
		dispatch(setActivePage(path));
		navigate(path);
	};

	function classNames(...classes: any) {
		return classes.filter(Boolean).join(" ") as string;
	}
	if (websocket.connected) {
		return (
			<>
				<Topbar />
				<div className="h-screen w-screen">
					<div className="p-10">
						<nav aria-label="Progress">
							<ol
								role="list"
								className="space-y-4 md:flex md:space-y-0 md:space-x-8"
							>
								{app.steps.map((step) => {
									let color: string = "gray";

									if (
										step.status === "complete" &&
										step.completed === true
									)
										color = "emerald";
									else if (step.status === "current")
										color = "yellow";
									else if (
										step.status === "complete" &&
										step.completed === false
									)
										color = "red";

									return (
										<li
											key={step.name}
											className="md:flex-1"
										>
											<span
												onClick={() =>
													navigateTo(step.href)
												}
												className={`group cursor-pointer flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0 border-${color}-500 hover:border-${color}-700 `}
											>
												<span
													className={`text-sm font-medium group-hover:text-${color}-500 text-${color}-400`}
												>
													{step.id}
												</span>
												<span className="text-sm font-medium">
													{step.name}
												</span>
											</span>
										</li>
									);
								})}
							</ol>
						</nav>
					</div>
					<div className="flex w-full row-span-5 p-10">
						<Outlet />
					</div>
				</div>
				<Notification />
			</>
		);
	} else {
		return (
			<div>
				<Topbar />
				loading
			</div>
		);
	}
}

export default App;
