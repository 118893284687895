import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOwner as IOwnerTable } from '../table/table.interface';
import { IOwner, IWebSocketConfig } from './websocket.interface';
import { addSelected, addMySelected, setSelected, setMySelected } from '../identity/identity.slice';
import { setOwners } from '../table/table.slice';
import { setConfig } from './websocket.slice';
import { setConnected } from './websocket.slice';

const host = '';

export const WebsocketConnectAsync = createAsyncThunk(
   'WebSocket/WebsocketConnect',
    async () => {}
)

export const addListenersAsync = createAsyncThunk(
   'WebSocket/addListeners',

    async (_, {dispatch, getState}) => {
        console.log("AddListenersAsync");
        const {websocket, table, identity} = getState() as any;

        websocket.socket.on("connect", () => {
            console.log("Connected");
            dispatch(setConnected(true));
        })

        websocket.socket.on("disconnect", () => {
            dispatch(setConnected(false));
        })

        websocket.socket.on("reconnect", () => {
            dispatch(setConnected(true));
        })

        websocket.socket.on("owners", (owners: IOwner[])=> {
            dispatch(setOwners(owners));

        })
        websocket.socket.on("message", (message: string)=> {
            console.log(message);
        })

        websocket.socket.on("selected", (selected: IOwner[])=> {
            const mySelected = selected.filter(owner => owner.naam === identity.identity.name && owner.email === identity.identity.email);
            // dispatch(setMySelected(mySelected));

            const otherSelected = selected.filter(owner => owner.naam !== identity.identity.name || owner.email !== identity.identity.email);
            dispatch(setSelected(otherSelected));
        })

        websocket.socket.on("config", (selected: IWebSocketConfig[])=> {
            dispatch(setConfig(selected));
        });
        return;
    }
)

export const addOwnersAsync = createAsyncThunk(
   'table/addOwners',
    async (_, {dispatch, getState}) => {
        const {websocket, table, identity} = getState() as any;
        const selected: IOwner[] = [];

        for (const scope in identity.mySelected) {
            for (const x in identity.mySelected[scope]) {
                for (const y in identity.mySelected[scope][x]) {
                    const owner: IOwner = {
                        betaald: false,
                        email: identity.identity.email,
                        naam: identity.identity.name,
                        x,
                        y,
                        scope: scope,
                        telefoon: identity.identity.telefoon,
                        naamJeugdBeweging: identity.identity.naamJeugdBeweging,
                        isJeugdbeweging: identity.identity.isJeugdbeweging,
                        price: identity.totalPrice,
                    };
                    selected.push(owner);
                }
            }
        }


        websocket.socket.emit("addOwners", selected);

        dispatch(setMySelected([]));
        websocket.socket.emit("clearSelected", [{
            email: identity.identity.email,
            name: identity.identity.name,
        }]);
    }
)

export const addSelectedAsync = createAsyncThunk(
   'table/addSelectedAsync',
    async (_, {dispatch, getState}) => {
        const {websocket, table, identity} = getState() as any;
        const selected: IOwner[] = [];
        // console.log(table.mySelected);
        
        for (const scope in identity.mySelected) {
            for (const x in identity.mySelected[scope]) {
                for (const y in identity.mySelected[scope][x]) {
                    const owner: IOwner = {
                        betaald: false,
                        email: identity.identity.email,
                        naam: identity.identity.name,
                        x,
                        y,
                        scope: scope,
                        telefoon: identity.identity.telefoon,
    
                        naamJeugdBeweging: identity.identity.naamJeugdBeweging,
                        isJeugdbeweging: identity.identity.isJeugdbeweging,
                        price: 0,
                    };
                    selected.push(owner);
                }
            }
        }


        websocket.socket.emit("addSelected", selected);
        // dispatch(setMySelected([]));
        // websocket.socket.emit("clearSelected", [{
        //     email: table.identity.email,
        //     name: table.identity.name,
        // }]);

        return;
    }
)

export const removeSelectedAsync = createAsyncThunk(
   'table/removeSelected',
    async (owner: IOwner, {dispatch, getState}) => {
        const {websocket, table} = getState() as any;
        websocket.socket.emit("removeSelected", owner);
    }
)