import { createSlice, PayloadAction, current } from '@reduxjs/toolkit'

import { IIdentity } from './identity.interface'
import { IOwner } from '../webSocket/websocket.interface'

interface IIdentityState {
    // state
    identity: IIdentity,
	variableIdentity: IIdentity,
	jeugdBeweging: boolean,
    selected: {
		[scope: string]: {
			[x: string]: {
				[y:string]: IIdentity;
			}
		}

	},
	mySelected: {
		[scope: string]: {

			[x: string]: {
				[y:string]: IIdentity;
			}
		}
	},
	totalPrice: number,
}

const initialState: IIdentityState = {
    identity: {
        name: '',
        email: '',
        bemail: '',
		telefoon: '',
		naamJeugdBeweging: '',
		isJeugdbeweging: false,
		scope: ''
    },
	variableIdentity: {
        name: '',
        email: '',
        bemail: '',
		telefoon: '',
		naamJeugdBeweging: '',
		isJeugdbeweging: false,
		scope: ''
    },
    selected: {},
    mySelected: {},
	jeugdBeweging: false,
	totalPrice: 0,
}

const IdentitySlice = createSlice({

    name: 'Identity',
    initialState,
    reducers: {
		setTotalPrice(state, action: PayloadAction<number>) {
			state.totalPrice = action.payload;
		},
		setJeugdBeweging(state, action: PayloadAction<boolean>) {
			state.jeugdBeweging = action.payload;
			state.identity.isJeugdbeweging = action.payload;
			state.variableIdentity.isJeugdbeweging = action.payload;
		},

        updateIdentity(state, action: PayloadAction<IIdentity>) {
            state.variableIdentity = action.payload;
        },
        saveIdentity(state) {
            state.identity = state.variableIdentity;
        },

        removeSelected(state, action: PayloadAction<{x: string, y:string, scope: string}> ){
			delete state.mySelected[action.payload.scope][action.payload.x][action.payload.y];
		},
		forceSetMySelected(state, action: PayloadAction<{
			[scope: string]: {
				[x: string]: {
					[y: string]: IIdentity;
				}
			};

		}> ){
			state.mySelected = action.payload;
		},
        setMySelected(state, action: PayloadAction<IOwner[]>) {

			const selectedTable: {
				[scope: string]: {
					[x: string]: {
						[y: string]: IIdentity;
					}
				};
			} = {};

			for (const owner of action.payload) {
				const {x, y, naam, email, telefoon, naamJeugdBeweging, isJeugdbeweging, scope} = owner;
				const identity: IIdentity = {
					name: naam,
					email,
					bemail: email,
					telefoon,
					naamJeugdBeweging,
					isJeugdbeweging,
					scope
				}
				selectedTable[scope] = {
					...selectedTable[scope],
					[x]: {
						...selectedTable[scope]?.[x],
						[y]: identity
					}
				}

			}
			state.mySelected = selectedTable;
		},

        updateMySelected(state, action: PayloadAction<{
			[scope: string]: {

				[x: string]: {
					[y:string]: IIdentity;
				}
			}
		}>) {
			state.mySelected = action.payload;

		},
        addMySelected(state, action: PayloadAction<{x: string, y: string, scope: string}>) {
			// console.log(state.mySelected[action.payload.x], action.payload.x);
			// console.log(state.identity.scope);
			state.mySelected[action.payload.scope] = {
				...state.mySelected[action.payload.scope],
				[action.payload.x]: {
					...state.mySelected[action.payload.scope]?.[action.payload.x],
					[action.payload.y]: {
						name: state.identity.name,
						email: state.identity.email,
						bemail: state.identity.email,
						telefoon: state.identity.telefoon,
						naamJeugdBeweging: state.identity.naamJeugdBeweging,
						isJeugdbeweging: state.identity.isJeugdbeweging,
						scope: action.payload.scope
					}
				}
			};






			// console.log(current(state.mySelected));
		},

		setSelected(state, action: PayloadAction<IOwner[]>) {
			const selectedTable: {
				[scope: string]: {
					[x: string]: {
						[y: string]: IIdentity;
					};
				}

			} = {};
			// console.log(action.payload);

			for (const owner of action.payload) {
				// console.log(owner);
				if (owner === null) continue;
				const {x, y, naam, email, scope, naamJeugdBeweging, isJeugdbeweging, telefoon} = owner;
				const identity: IIdentity = {
					name: naam,
					email,
					bemail: email,
					telefoon,
					naamJeugdBeweging,
					isJeugdbeweging,
					scope
				}
				let loc = `${scope}.${x}`;
				// console.log(loc);
				selectedTable[scope] = {
					...selectedTable[scope],
					[x]: {
						...selectedTable[scope]?.[x],
						[y]: identity
					},
				}
			}
			// console.log(selectedTable, action.payload);
			state.selected = selectedTable;
		},

        addSelected(state, action: PayloadAction<{x: string, y: string, scope: string}>) {
			let location: string = `${action.payload.scope}.${action.payload.x}`;
			// console.log(location);
			state.selected[action.payload.scope] = {
				...state.selected[action.payload.scope],
				[action.payload.x]: {
					...state.selected[action.payload.scope][action.payload.x],
					[action.payload.y]: {
						name: state.identity.name,
						email: state.identity.email,
						bemail: state.identity.email,
						telefoon: state.identity.telefoon,
						naamJeugdBeweging: state.identity.naamJeugdBeweging,
						isJeugdbeweging: state.identity.isJeugdbeweging,
						scope: state.identity.scope
					}
				}
			};
		},
    }
    // extra reducers
})

export const { setTotalPrice,setJeugdBeweging, updateIdentity, saveIdentity, addMySelected, addSelected, forceSetMySelected, setMySelected, setSelected,updateMySelected, removeSelected } = IdentitySlice.actions
export default IdentitySlice.reducer