/** @format */

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {addOwnersAsync} from "../webSocket/websocket.actions";
import {setCompleted, setActivePage} from "../../App.slice";
import {setTotalPrice} from "../identity/identity.slice";
import {useNavigate} from "react-router-dom";
import Divider from "../../components/Divider";

let selected: string[] = [];
let totalPrice = 0;

interface ISummaryProps {}
const Summary = ({}: ISummaryProps) => {
	const dispatch = useAppDispatch();
	const {identity, table, websocket} = useAppSelector((state) => state);
	const navigate = useNavigate();

	const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

	const navigateTo = (path: string) => {
		dispatch(setActivePage(path));
		navigate(path);
	};

	const save = () => {
		dispatch(setCompleted("/summary"));
		dispatch(addOwnersAsync());
		dispatch(setTotalPrice(totalPrice));
		navigateTo("/success");
	};

	useEffect(() => {
		selected = [];
		for (const scope in identity.mySelected) {
			for (const x in identity.mySelected[scope]) {
				for (const y in identity.mySelected[scope][x]) {
					const a = parseInt(y);
					const xCoord = ALPHABET[a];
					selected.push(`${xCoord}${x} voor ${scope}`);
				}
			}
		}
	}, [identity.mySelected]);

	return (
		<div>
			<h1 className="text-2xl font-bold">Overzicht</h1>
			<div>
				Totaal:
				<span className="font-bold">€{identity.totalPrice}</span>
			</div>

			{websocket.config.map((scope, x) => {
				if (
					(scope.voorJeugdbeweging &&
						identity.identity.isJeugdbeweging) ||
					!scope.voorJeugdbeweging
				)
					return (
						<div className={x > 0 ? "mt-10" : ""}>
							{x > 0 ? <Divider /> : null}
							<h2 className="text-xl font-bold mt-4">
								{scope.naam}
							</h2>
							<table
								className="mt-10 w-full h-full rounded-lg overflow-hidden table-fixed"
								key={x}
							>
								<thead>
									<tr>
										<th></th>
										{Array.from(
											{length: table.ncols},
											(_, i) => (
												<th
													key={i}
													className="text-center"
												>
													{ALPHABET[i]}
												</th>
											)
										)}
									</tr>
								</thead>
								<tbody>
									{Array.from(
										{length: table.nrows},
										(_, i) => (
											<tr key={i} className="w-full">
												<th>{i}</th>
												{Array.from(
													{length: table.ncols},
													(_, j) => {
														// console.log(selectedScope.naam);
														let owner: any = null;
														let mySelected: any =
															null;
														let selected = null;

														if (
															table.selectedScope
														) {
															mySelected =
																identity
																	.mySelected[
																	scope.naam
																]?.[i]?.[j];
															selected =
																identity
																	.selected[
																	scope.naam
																]?.[i]?.[j];
														}

														let color: string =
															"bg-white";

														if (selected) {
															if (
																selected.name ===
																	identity
																		.identity
																		.name &&
																selected.email ===
																	identity
																		.identity
																		.email &&
																selected.scope ===
																	scope.naam
															) {
																if (mySelected)
																	color =
																		"bg-green-400";
																else
																	color =
																		"bg-white";
															} else {
																color =
																	"bg-gray-200 hover:bg-gray-100";
															}
														}
														// if (!mySelected) {
														// 	color = "bg-gray-200 hover:bg-gray-100";
														// }
														if (owner) {
															if (owner.betaald) {
																color =
																	"bg-red-400 hover:bg-red-100";
															} else {
																color =
																	"bg-yellow-300 hover:bg-yellow-100";
															}
														}
														return (
															<td
																key={j}
																className={` border border-gray-200 ${color} transition duration-300 ease-in-out`}
															></td>
														);
													}
												)}
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
					);
			})}

			<div className="mt-10 w-full flex justify-end">
				<button
					type="button"
					className="ml-2 inline-flex justify-center rounded-md border border-indigo-700 bg-white px-4 py-2 text-base font-medium text-indigo-700 shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm transition"
					onClick={() => navigateTo("/kiesjevak")}
				>
					Terug naar stap 3
				</button>
				<button
					type="button"
					className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
					onClick={() => save()}
				>
					Opslaan
				</button>
			</div>
		</div>
	);
};

export default Summary;
