import { configureStore, combineReducers, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import tableReducer from '../features/table/table.slice';
import websocketReducer from '../features/webSocket/websocket.slice';
import appReducer from '../App.slice';

import storage from 'redux-persist/lib/storage';
import { 
  persistReducer, 
  persistStore,  
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER } from 'redux-persist';
import identityReducer from '../features/identity/identity.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Identity'],
  // blacklist: ['table', 'WebSocket', 'App']
}

const reducer = combineReducers({
  table: tableReducer,
  websocket: websocketReducer,
  app: appReducer,
  identity: identityReducer
})
const persistedReducer = persistReducer(persistConfig, reducer);


const customMiddleware = (store: any) => (next: any) => (action: any) => {
  // console.log('Middleware', action);
  next(action);
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(customMiddleware),

})
export const persistor = persistStore(store)

// store.subscribe(() => localStorage.setItem("reduxState", JSON.stringify(store.getState())));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
