import { createSlice, PayloadAction } from '@reduxjs/toolkit'
interface IStep {
    id: string,
    name: string,
    href: string,
    status: "complete" | "current" | "upcoming",
    completed: boolean,
}
interface IAppState {
    steps: IStep[],
    userInfoSuccess: boolean,
    userInfoError: string,
}
const initialState: IAppState = {
    steps: [
        {
			id: "Stap 1",
			name: "Welkom",
			href: "/",
			status: "current",
            completed: false
		},
        {
			id: "Stap 2",
			name: "Jouw gegevens",
			href: "/gegevens",
			status: "upcoming",
            completed: false
		},
		{
			id: "Stap 3",
			name: "Kies je vakken",
			href: "/kiesjevak",
			status: "upcoming",
            completed: false
		},
		{
            id: "Stap 4", 
            name: "Samenvatting", 
            href: "/summary", 
            status: "upcoming",
            completed: false
        },
        {
            id: "Stap 5", 
            name: "Klaar", 
            href: "/success", 
            status: "upcoming",
            completed: false
        },
    ],
    userInfoSuccess: false,
    userInfoError: "",
}

const AppSlice = createSlice({

    name: 'App',
    initialState,
    reducers: {
        setInfoSuccess: (state, action: PayloadAction<boolean>) => {
            state.userInfoSuccess = action.payload
        },
        setActivePage: (state, action: PayloadAction<string>) => {
            state.steps = state.steps.map(step => {
                if (step.href === action.payload) {
                    step.status = "current"
                } else if (step.status === "current") {
                    step.status = "complete"
                }
                return step
            })
        },
        setCompleted: (state, action: PayloadAction<string>) => {
            state.steps = state.steps.map(step => {
                if (step.href === action.payload) {
                    step.completed = true
                    step.status = "complete"
                }
                return step
            })
        },

        setError(state, action: PayloadAction<string>) {
            state.userInfoError = action.payload
        },
    }
    // extra reducers
})

export const { setActivePage, setError, setInfoSuccess, setCompleted } = AppSlice.actions
export default AppSlice.reducer