/** @format */

import {createSlice, PayloadAction, current} from "@reduxjs/toolkit";
import type {RootState} from "../../app/store";

import { IOwner, IWebSocketConfig } from "../webSocket/websocket.interface";

import { IIdentity } from "../identity/identity.interface";

import React from "react";

import {ITableState} from "./table.interface";

const initialState: ITableState = {
	nrows: 10,
	ncols: 15,
	owners: {},
	loading: true,
	selectedScope: undefined,
};

export const tableSlice = createSlice({
	name: "table",
	initialState,
	reducers: {
		setSelectedScope(state, action: PayloadAction<IWebSocketConfig | undefined>) {
			state.selectedScope = action.payload;
		},
		setOwners(state, action: PayloadAction<IOwner[]>) {
			const selectedTable: {
				[scope: string]: {
					[x: string]: {
						[y: string]: IOwner;
					};
				}

			} = {};
			for (const owner of action.payload) {
				
				const {x, y, naam, email, betaald, scope, telefoon, naamJeugdBeweging, isJeugdbeweging} = owner;
				selectedTable[scope] = {
					...selectedTable[scope],
					[x]: {
						...selectedTable[scope]?.[x],
						[y]: {
							naam,
							email,
							betaald,
							x,
							y,
							scope,
							telefoon,
							naamJeugdBeweging,
							isJeugdbeweging,
							price: 0
						}
					}

				}
			}
			state.loading = false;
			state.owners = selectedTable;
		},

	},
	extraReducers: (builder) => {

	}
	
});

export const selectTable = (state: RootState) => state.table;
export const { setOwners, setSelectedScope } = tableSlice.actions
export default tableSlice.reducer;
