/** @format */

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {addOwnersAsync} from "../webSocket/websocket.actions";
import {setCompleted} from "../../App.slice";

interface ISuccessProps {}
const Success = ({}: ISuccessProps) => {
	const dispatch = useAppDispatch();
	const {identity, table, app} = useAppSelector((state) => state);

	useEffect(() => {
		dispatch(setCompleted("/success"));
	}, []);

	return (
		<div className="w-full flex justify-center">
			<div className="w-full sm:w-2/3 lg:w-1/2">
				<div className="flex items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-20 h-20 text-green-600"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>

					<h1 className="text-2xl ml-4">Super!</h1>
				</div>

				<p className="mt-10">
					Jouw vakjes zijn opgeslagen, om deze te bevestigen dien je
					het totale bedrag over te schrijven naar onze
					Chiro-rekening:
				</p>
				<p>BE83 7370 0314 9715.</p>
				<p className="mt-2">
					Je krijgt ook nog een mailtje als bevestiging.
				</p>
			</div>
		</div>
	);
};

export default Success;
